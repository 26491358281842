import MobileStoreButton from 'react-mobile-store-button'

import iOS1 from '../../assert/ios_app_1.png'
import iOS2 from '../../assert/ios_app_2.png'
import iOS3 from '../../assert/ios_app_3.png'
import iOS4 from '../../assert/ios_app_4.png'

export const MobileApps = (props) => {
  return (
    <section id="mobileapp">
        <div className="container">
          {/* <div className='section-title text-center'>
            <h2>Mobile App</h2>
          </div> */}
          {/* <div className="row mt-5">
            <div className="col-md-3 col-sm-6 col-6">
              <img src={iOS1} style={{ width: '100%' }} alt="iOS"/>
            </div>
            <div className="col-md-3 col-sm-6 col-6">
              <img src={iOS2} style={{ width: '100%' }} alt="iOS"/>
            </div>
            <div className="col-md-3 col-sm-6 col-6">
              <img src={iOS3} style={{ width: '100%' }} alt="iOS"/>
            </div>
            <div className="col-md-3 col-sm-6 col-6">
              <img src={iOS4} style={{ width: '100%' }} alt="iOS"/>
            </div>
          </div> */}
          <div className="row mt-5 d-flex align-items-center justify-content-between">
            <div className="text-center">
              <MobileStoreButton
                store="ios"
                url={'https://apps.apple.com/us/app/mcjtools/id1609320410'}
                linkProps={{ title: 'McJ Tools' }}/>
            </div>
          </div>
        </div>
    </section>
  )
}
